import { Link } from "react-router-dom";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { useTranslation } from 'react-i18next';

import '@splidejs/react-splide/css';
import "./BannerSlider.scss"

const BannerSlider = ({ title, slides }) => {

    const { t } = useTranslation();

    return (
        <div className='bannerSlider'>
            <div className='bannerSlider__backHome'>
                <Link to="/#projects">
                    <img className="iconBackHome" src="../../../images/general/arrow.svg" alt="go to project"/>
                    <p className='bannerSlider__textBackHome'>{t(`bannerSlider.backHome`)}</p>
                </Link>
            </div>
            <Splide
                aria-label="Images list"
                hasTrack={false}
                options={{
                    perPage: 1,
                    perMove: 1,
                    pagination: true,
                    direction: 'ttb',
                    height: "100vh",
                    breakpoints: {
                        // Under 1024
                        1024: {
                            height: "50vh",
                        }
                    }
                }}>
                <SplideTrack>
                    {slides.map((slide, index) => {
                        return (
                            <SplideSlide key={index}>
                                <div className='bannerSlider__item'>
                                    <img className='bannerSlider__img' src={slide.image} alt={slide.alt} />
                                </div>
                            </SplideSlide>
                        )
                    })}
                </SplideTrack>

                {/* CUSTOM ARROWS */}
                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="previous slide"/>
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="next slide"/>
                    </button>
                </div>
            </Splide>
        </div>
    )
}

export default BannerSlider;