import { useTranslation } from 'react-i18next';

import "./NextProject.scss"

const NextProject = ({ link }) => {

    const { t } = useTranslation();

    return (
        <div className='nextProject'>
            <a href={link} className='nextProject__link'>
                <p className='nextProject__text'>{t('nextProject.text')}</p>
                <img className="nextProject__imgArrow" src="../../../images/general/arrow.svg" alt="next project"/>
            </a>
        </div>
    )
}

export default NextProject;