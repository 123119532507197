import "./SixEItem.scss"
import { useState } from 'react';

const SixEItem = ({ logo, title, identificator, clickPassed }) => {


    const [classHover, setClassHover] = useState("");

    const handleClick = (e) => {
        e.preventDefault()
        clickPassed(identificator)
    }

    const handleMouseEnter = (e) => {
        e.preventDefault()
        setClassHover("active");
    }

    const handleMouseLeave = (e) => {
        e.preventDefault()
        setClassHover("");
    }

    return (
        <a
            id={identificator}
            className={`sixE__element ${classHover}`}
            href="#"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className={`sixE__elementLogo sixE__elementLogo-${logo}`}>
                <img src={`images/logos/${logo}.svg`} className="sixE__logo"></img>
            </div>
            <div className="sixE__elementTitle" dangerouslySetInnerHTML={{ __html: title }}></div>
        </a>
    )
}

export default SixEItem;
