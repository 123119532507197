import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { PartnersItem } from "../../atoms";

import { Parallax } from 'react-scroll-parallax';

import '@splidejs/react-splide/css';
import "./Partners.scss"

const Partners = ({ title, slides }) => {

    return (
        <div id="partner" className='slider slider--partners'>
            <Parallax speed={4}>
                <div className='sliderPartner__titleWrapper'>
                    <h2 className='sliderPartner__title'>{title}</h2>
                </div>
                
            </Parallax>
            <Splide
                className='sliderPartner__splide'
                aria-label="my projects"
                hasTrack={false}
                options={{
                    type: 'loop',
                    perPage: 1,
                    perMove: 1,
                    gap: "48px",
                    pagination: false,
                    breakpoints: {
                        // Under 920
                        1920: {
                            type: 'loop',
                            perPage: 1,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                        // Under 1024
                        1024: {
                            type: 'loop',
                            perPage: 1,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                        // Under 1024
                        768: {
                            type: 'loop',
                            perPage: 1,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                    }
                }}>
                <SplideTrack>
                    {slides.map((slide, index) => {
                        return (
                            <SplideSlide key={index}>
                                <div className="partners__slide">
                                    {slide.partners.map((el, index2) => {
                                        return (
                                            <PartnersItem key={index2} logo={el.image} link={el.linkToProject}></PartnersItem>
                                        )
                                    })}
                                </div>
                            </SplideSlide>
                        )
                    })}
                </SplideTrack>

                {/* CUSTOM ARROWS */}
                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="previous slide" />
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="next slide" />
                    </button>
                </div>
            </Splide>
        </div>
    )
}

export default Partners;