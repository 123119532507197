import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const NoPage = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [])

  return (
    <div className="noPage">
      <Helmet>
        <title>No page | Instituto E</title>
      </Helmet>
      <h1 className="noPage__title">{t('noPage.title')}</h1>
    </div>
  )
};

export default NoPage;