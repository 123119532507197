import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../../atoms/ChangeLanguage/ChangeLanguage';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import "./BannerMain.scss"

const voices = [
    {
        link: "about",
        name: "about"
    },
    {
        link: "projects",
        name: "projects"
    },
    {
        link: "partner",
        name: "partner"
    },
    {
        link: "contacts",
        name: "contacts"
    }
]

const socials = [
    {
        link: "https://www.instagram.com/institutoe",
        social: "instagram"
    },
    {
        link: "https://www.linkedin.com/company/institutoe/about/",
        social: "linkedin"
    },
    {
        link: "https://www.facebook.com/Institutoeorg",
        social: "facebook"
    },
    {
        link: "https://www.youtube.com/user/institutoe",
        social: "youtube"
    }
];

const BannerMain = ({ srcVideo }) => {

    const { t } = useTranslation();

    const [openMenu, setOpenMenu] = useState(false);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
        document.getElementsByTagName("html")[0].classList.toggle("blockScroll")
    }

    const renderVoicesMenu = (el, key) => {
        return (
            <li className='menu__item' key={key}>
                {el.link === "about"
                    ?
                    <a className='menu__link' href={el.link}>
                        <p className='menu__voice'>{t(`menu.${el.name}`)}</p>
                    </a>
                    :
                    <AnchorLink href={`#${el.link}`}>
                        <div className='menu__link' data-link={el.link} onClick={toggleMenu}>
                            <p className='menu__voice'>{t(`menu.${el.name}`)}</p>
                        </div>
                    </AnchorLink>
                }
            </li>
        )
    }

    const renderSocials = (el, key) => {
        return (
            <a href={el.link} className="menu__linkSocial" key={key}>
                <img className="menu__imgSocial" src={`../../../images/socials/${el.social}.svg`} alt={`logo ${el.social}`} />
            </a>
        )
    }

    return (
        <div className={`menu ${openMenu ? "menu--open" : ""}`}>
            <div className="hamburger">
                <button className="hamburger__toggle" onClick={toggleMenu}>
                    <div className="hamburger__icon">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </button>
            </div>
            <div className="menu__wrapper">
                <div className='menu__content'>
                    <ul className='menu__list'>
                        {voices.map((el, key) => renderVoicesMenu(el, key))}
                    </ul>
                    <ChangeLanguage />
                    <div className="menu__social">
                        {socials.map((el, key) => renderSocials(el, key))}
                    </div>
                </div>
            </div>
            <div className="bannerMain">
                <div className='bannerMain__background'>
                    <video className="bannerMain__video" muted autoPlay loop playsInline>
                        <source src={srcVideo} type="video/mp4" />
                    </video>
                </div>
                <div className='bannerMain__content'>
                    <img className="bannerMain__logo" src={`../../../images/logos/logoBannerMain.svg`} alt={`logo instituto e`} />
                    <div className='bannerMain__info'>
                        <h1 className='bannerMain__title'>
                            {t(`bannerMain.title1`)}<br />
                            {t(`bannerMain.title2`)}
                        </h1>
                        <h2 className='bannerMain__subtitle'>
                            {t(`bannerMain.subtitle1`)}<br />
                            {t(`bannerMain.subtitle2`)}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerMain;