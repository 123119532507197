import "./Button.scss"

const Button = ({label, link}) => {
    console.log("Button Render");
    return (
        <div className="button">
            <a className="button__link" href={link} target="_blank">
                <p className="button__text">{label}</p>
            </a> 
        </div>
    )
} 

export default Button;