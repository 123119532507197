import { useTranslation } from 'react-i18next';

import "./ProjectAwards.scss"

const ProjectAwards = ({ awards }) => {

    const { t } = useTranslation();

    return (
        <div className='projectAwards'>
            <h2 className='projectAwards__title'>{t('project.titleAwards')}</h2>
            <div className='projectAwards__info'>
                {awards.map((singleAward, keyAward) => {
                    return (
                        <div className='projectAwards__award' key={keyAward}>
                            <img className='projectAwards__img' src={singleAward.imageLogo}></img>
                            <p className='projectAwards__text'>{singleAward.textAward}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectAwards;