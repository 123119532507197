import { useTranslation } from 'react-i18next';

import "./ProjectDownloads.scss"

const ProjectDownloads = ({ downloads }) => {

    const { t } = useTranslation();

    return (
        <div className='projectDownloads'>
            <h2 className='projectDownloads__title'>{t('project.titleDownloads')}</h2>
            <div className='projectDownloads__info'>
                {downloads.map((singleDownload, keyDownload) => {
                    return (
                        <div className='projectDownloads__singleDownload' key={keyDownload}>
                            <a href={singleDownload.fileToDownload} className="projectDownloads__aDownload" download>
                                <p className='projectDownloads__textVideo'>{singleDownload.textDownload}</p>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectDownloads;