import { useTranslation } from 'react-i18next';

import "./ProjectLinks.scss"

const ProjectLinks = ({ links }) => {

    const { t } = useTranslation();

    return (
        <div className='projectLinks'>
            <h2 className='projectLinks__title'>{t('project.titleLinks')}</h2>
            <div className='projectLinks__info'>
                {links.map((singleLinks, keyLink) => {
                    return (
                        <div className='projectLinks__singleLinks' key={keyLink}>
                            <a href={singleLinks.linkUrl} className="projectLinks__aLink" target="_blank">
                                <p className='projectLinks__textLink'>{singleLinks.textLink}</p>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectLinks;