import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import NoPage from "./pages/NoPage";
import ProjectBase from "./pages/ProjectBase";
// Projects
import ProjectAmazonGuardian from "./pages/ProjectAmazonGuardian";
import ProjectAsap from "./pages/ProjectAsap";
import ProjectAshaninka from "./pages/ProjectAshaninka";
import ProjectEAward from "./pages/ProjectEAward";
import ProjectEAyiti from "./pages/ProjectEAyiti";
import ProjectEBrigade from "./pages/ProjectEBrigade";
import ProjectEFabrics from "./pages/ProjectEFabrics";
import ProjectHubE from "./pages/ProjectHubE";
import ProjectKeel from "./pages/ProjectKeel";
import ProjectOsklen from "./pages/ProjectOsklen";
import ProjectPortobello from "./pages/ProjectPortobello";
import ProjectRestinga from "./pages/ProjectRestinga";
import ProjectSustainability from "./pages/ProjectSustainability";
import ProjectTraces from "./pages/ProjectTraces";
import ProjectWaterTraces from "./pages/ProjectWaterTraces";

function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/project-base" element={<ProjectBase />} />
            <Route path="/project-amazon-guardian" element={<ProjectAmazonGuardian />} />
            <Route path="/project-asap" element={<ProjectAsap />} />
            <Route path="/project-ashaninka" element={<ProjectAshaninka />} />
            <Route path="/project-e-award" element={<ProjectEAward />} />
            <Route path="/project-e-ayiti" element={<ProjectEAyiti />} />
            <Route path="/project-e-brigade" element={<ProjectEBrigade />} />
            <Route path="/project-e-fabrics" element={<ProjectEFabrics />} />
            <Route path="/project-hub-e" element={<ProjectHubE />} />
            <Route path="/project-keel" element={<ProjectKeel />} />
            <Route path="/project-osklen" element={<ProjectOsklen />} />
            <Route path="/project-portobello" element={<ProjectPortobello />} />
            <Route path="/project-restinga" element={<ProjectRestinga />} />
            <Route path="/project-sustainability" element={<ProjectSustainability />} />
            <Route path="/project-traces" element={<ProjectTraces />} />
            <Route path="/project-water-traces" element={<ProjectWaterTraces />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
