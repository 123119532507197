import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { Parallax } from 'react-scroll-parallax';
import Tilt from 'react-tilt'

import { useTranslation } from 'react-i18next';

import '@splidejs/react-splide/css';
import "./Slider.scss"

const Slider = ({ title, slides }) => {

    const { t } = useTranslation();

    return (
        <div className='slider'>
            <Parallax speed={4}>
                <h2 className='slider__title'>{title}</h2>
            </Parallax>
            <Splide
                aria-label="my projects"
                hasTrack={false}
                options={{
                    type: 'loop',
                    perPage: 4.5,
                    perMove: 1,
                    gap: "48px",
                    pagination: false,
                    breakpoints: {
                        // Under 920
                        1919: {
                            type: 'loop',
                            perPage: 3.5,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                        // Under 1024
                        1023: {
                            type: 'loop',
                            perPage: 2.5,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                        // Under 1024
                        767: {
                            type: 'loop',
                            perPage: 1.5,
                            perMove: 1,
                            gap: "48px",
                            pagination: false,
                        },
                    }
                }}>
                <SplideTrack>
                    {slides.map((slide, index) => {
                        return (
                            <SplideSlide key={index}>
                                <a href={slide.linkToProject} className='slider__item'>
                                    <img className='slider__img' src={slide.image} alt={slide.alt} />
                                    <div className='slider__info'>
                                        <Tilt className="slider__tilt" options={{ max: 25 }}>
                                            <p className='slider__itemTitle' dangerouslySetInnerHTML={{ __html: t(`slider.${slide.title}`) }}></p>
                                            {slide.subtitle && <p className='slider__itemSubtitle'>{t(`slider.${slide.subtitle}`)}</p>}
                                        </Tilt>
                                    </div>
                                </a>
                            </SplideSlide>
                        )
                    })}
                </SplideTrack>

                {/* CUSTOM ARROWS */}
                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="previous slide" />
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                        <img className="splide__imgArrow" src="../../../images/general/arrow.svg" alt="next slide" />
                    </button>
                </div>
            </Splide>
        </div>
    )
}

export default Slider;