import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectSustainability) in translations
const pathProject = "projectSustainability";

const ProjectSustainability = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>Sustainability | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/sustainability/sustainability-1.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            }
          ]} />

        <ProjectDownloads
          downloads={[
            {
              fileToDownload: "../../../pdf/SustainbilityHighlights.pdf",
              textDownload: `${t(`${pathProject}.downloadText1`)}`
            },
            {
              fileToDownload: `${t(`${pathProject}.downloadLink2`)}`,
              textDownload: `${t(`${pathProject}.downloadText2`)}`
            },
            {
              fileToDownload: `${t(`${pathProject}.downloadLink3`)}`,
              textDownload: `${t(`${pathProject}.downloadText3`)}`
            }
          ]} />
      </div>

      <NextProject link="project-e-ayiti"></NextProject>

    </div>
  )
};

export default ProjectSustainability;
