import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import UseSetVh from "../scripts/hooks/UseSetVh.jsx"

const Layout = () => {

  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  const mainWrapper = useRef();
  const vh = UseSetVh();
  useEffect(() => {
    const currentNav = mainWrapper.current
    currentNav.style.setProperty('--vh', vh);
  }, [vh]);

  return (
    <div className='mainWrapper' ref={mainWrapper}>

      {/* Uncomment this to see the list pages
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </nav> 
      */}

      <Outlet />
    </div>
  )
};

export default Layout;