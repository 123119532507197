import { useTranslation } from 'react-i18next';

import "./ProjectImpacts.scss"

const ProjectImpacts = ({ impacts }) => {

    const { t } = useTranslation();

    return (
        <div className='projectImpacts'>
            <h2 className='projectImpacts__title'>{t('project.titleImpacts')}</h2>
            <div className='projectImpacts__info'>
                {impacts.map((singleImpact, keyImpact) => {
                    return (
                        <div className='projectImpacts__impact' key={keyImpact}>
                            <p className='projectImpacts__impactTitle' dangerouslySetInnerHTML={{ __html: singleImpact.impactTitle }}></p>
                            <p className='projectImpacts__impactSubtitle' dangerouslySetInnerHTML={{ __html: singleImpact.impactSubtitle }}></p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectImpacts;