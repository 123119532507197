import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import "./ChangeLanguage.scss"

const ChangeLanguage = ({ }) => {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en");

    useEffect(() => {
        const lng = localStorage.getItem("language");
        setLanguage(lng);
    },[])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        localStorage.setItem("language", lng);
    };

    return (
        <div className='changeLanguage'>
            <p className={`changeLanguage__linkLanguage ${language === "pt" ? "active" : ""}`} onClick={() => changeLanguage("pt")}>PT</p>
            <p className={`changeLanguage__linkLanguage ${language === "en" ? "active" : ""}`} onClick={() => changeLanguage("en")}>ENG</p>
        </div>
    )
}

export default ChangeLanguage;