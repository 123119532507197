import "./PartnersItem.scss"

const PartnersItem = ({ logo, link }) => {
    return (
        <div className="partners__element">
            <a className="partners__goTo" href={link} target="_blank">
                <img src={`../../../images/partners/${logo}.svg`}></img>
            </a>
        </div>
    )
}

export default PartnersItem;
