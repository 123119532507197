import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectEAward) in translations
const pathProject = "projectEAward";

const ProjectEAward = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>E-Award | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/e-award/1.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p3`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p4`)}`
            }
          ]} />

        <ProjectVideos
          videos={[
            {
              linkYT: 'https://www.youtube.com/watch?v=g9P_0bi4tto&ab_channel=Instituto-e',
              textVideo: `${t(`${pathProject}.videoText1`)}`
            }
          ]} />

      </div>

      <NextProject link="project-asap"></NextProject>

    </div>
  )
};

export default ProjectEAward;
