import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectEFabrics) in translations
const pathProject = "projectEFabrics";

const ProjectEFabrics = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>E-Fabrics | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/e-fabrics/e-fabrics-1.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/e-fabrics/e-fabrics-2.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/e-fabrics/e-fabrics-3.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/e-fabrics/e-fabrics-4.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p3`)}`
            },
            {
              type: "list",
              list: [
                {
                  li: `${t(`${pathProject}.l1`)}`
                },
                {
                  li: `${t(`${pathProject}.l2`)}`
                },
                {
                  li: `${t(`${pathProject}.l3`)}`
                },
                {
                  li: `${t(`${pathProject}.l4`)}`
                },
                {
                  li: `${t(`${pathProject}.l5`)}`
                }
              ]
            }
          ]} />

        <ProjectImpacts
          impacts={[
            {
              impactTitle: `${t(`${pathProject}.impactTitle1`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle1`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle2`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle2`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle3`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle3`)}`
            }
          ]} />

        <p className='projectLayout__asterisco'>{t(`${pathProject}.asterisco`)}</p>

        <ProjectDownloads
          downloads={[
            {
              fileToDownload: "../../../pdf/E_Fabrics.pdf",
              textDownload: `${t(`${pathProject}.downloadText1`)}`
            }
          ]} />
      </div>

      <NextProject link="project-hub-e"></NextProject>

    </div>
  )
};

export default ProjectEFabrics;
