import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectHubE) in translations
const pathProject = "projectHubE";

const ProjectHubE = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>Hub-E | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/hub-e/1.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/hub-e/2.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/hub-e/3.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            }
          ]} />

        <ProjectImpacts
          impacts={[
            {
              impactTitle: `${t(`${pathProject}.impactTitle1`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle1`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle2`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle2`)}`
            }
          ]} />

        <ProjectLinks
          links={[
            {
              linkUrl: "https://www.instagram.com/hub_e_official",
              textLink: `${t(`${pathProject}.linkText1`)}`
            }
          ]} />

        <ProjectVideos
          videos={[
            {
              linkYT: 'https://www.youtube.com/watch?v=IxA-Za4Nz4U&ab_channel=Instituto-e',
              textVideo: `${t(`${pathProject}.videoText1`)}`
            }
          ]} />

        <ProjectDownloads
          downloads={[
            {
              fileToDownload: "../../../pdf/Hub_E.pdf",
              textDownload: `${t(`${pathProject}.downloadText1`)}`
            }
          ]} />
      </div>

      <NextProject link="project-ashaninka"></NextProject>

    </div>
  )
};

export default ProjectHubE;
