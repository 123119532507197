import { useState, useEffect } from 'react';

const UseSetVh = () => {
    const [vhValue, setVhValue] = useState(null);

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        setVhValue(`${vh}px`)
    }, []);

    return vhValue;
}

export default UseSetVh;