import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useIntersection } from 'react-use';
import "./About.scss"

const About = ({ }) => {
    const { t } = useTranslation();

    const [openAbout, setOpenAbout] = useState(null);
    const [enterEffectOne, setEnterEffectOne] = useState(false);
    const [enterEffectTwo, setEnterEffectTwo] = useState(false);

    const handleSecondSlide = () => {
        setOpenAbout(true);
    }

    const intersectionRefOne = useRef(null);
    const intersectionOne = useIntersection(intersectionRefOne, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    const intersectionRefTwo = useRef(null);
    const intersectionTwo = useIntersection(intersectionRefTwo, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    useEffect(() => {
        intersectionOne && intersectionOne.intersectionRatio < 1
            ? setEnterEffectOne(false)
            : setEnterEffectOne(true)
    }, [intersectionOne])

    useEffect(() => {
        intersectionTwo && intersectionTwo.intersectionRatio < 1
            ? setEnterEffectTwo(false)
            : setEnterEffectTwo(true)
    }, [intersectionTwo])

    return (
        <div className={`about ${openAbout ? "about--open" : ""}`} >
            <div className={`about__content ${enterEffectOne || enterEffectTwo ? "about--enter" : ""}`}>
                <div className="about__contentMaskOne" ref={intersectionRefOne}>
                    <h2 className="about__citeOne">{t("about.firstSlide-lineone")}</h2>
                    <h2 className="about__citeOne">{t("about.firstSlide-linetwo")}</h2>
                    <h2 className="about__citeOne">{t("about.firstSlide-linethree")}</h2>
                    <h2 className="about__citeOne">{t("about.firstSlide-linefour")}</h2>
                </div>
                <div className="about__contentMaskTwo" ref={intersectionRefTwo}>
                    <h2 className="about__citeTwo">{t("about.secondSlide-lineone")}</h2>
                    <h2 className="about__citeTwo">{t("about.secondSlide-linetwo")}</h2>
                    <h2 className="about__citeTwo">{t("about.secondSlide-linethree")}</h2>
                    <h2 className="about__citeTwo">{t("about.secondSlide-linefour")}</h2>
                </div>
            </div>
            {!openAbout
                ? (
                    <button className="about__buttonMore" onClick={(handleSecondSlide)}>
                        <img className="about__next" src="../../../images/general/arrow-next.svg" alt="icon next step" />
                        {t("about.buttonMore")}
                    </button>
                )
                : (
                    <Link className="about__goTo" to="/about">
                        <div className="about__buttonMore">
                            <img className="about__next" src="../../../images/general/arrow-next.svg" alt="icon page about" />
                            {t("about.buttonMore")}
                        </div>
                    </Link>
                )
            }
        </div>
    );
}

export default About;