import { Link } from "react-router-dom";
import "./InfoAbout.scss"

import { useTranslation } from 'react-i18next';
import { useState } from "react";

const methodologyItemList = ["mapping", "improvement", "strategy", "communication"];

const InfoAbout = ({ }) => {

    const [sectionVisible, setSectionVisible] = useState("about");

    const { t } = useTranslation();

    const handleSetSectionVisible = (section) => {
        setSectionVisible(section);
    }

    const renderItemList = () => {
        return methodologyItemList.map((el) => {
            return (
                <li className="infoAbout__itemList">
                    <p className="infoAbout__titleItemList">{t(`infoAbout.${el}.title`)}</p>
                    <p className="infoAbout__descItemList"
                        dangerouslySetInnerHTML={{ __html: t(`infoAbout.${el}.desc`) }}>
                    </p>
                </li>
            )
        })
    }

    return (
        <div className="infoAbout">
            <div className="infoAbout__background">
                <img className="infoAbout__img" src="../../../images/about/about-background.png"></img>
            </div>
            <div className="infoAbout__content">
                <div className='infoAbout__backHome'>
                    <Link to='/#about'>
                        <img className="infoAbout__iconBackHome" src="../../../images/general/arrow.svg" alt="go back home" />
                        <p className='infoAbout__textBackHome'>{t(`infoAbout.backHome`)}</p>
                    </Link>
                </div>
                <h1 className="infoAbout__title">{t(`infoAbout.title`)}</h1>
                <div className="infoAbout__main">
                    <div className="infoAbout__left">
                        <button
                            className={`infoAbout__button ${sectionVisible === "about" ? "isActive" : ""}`}
                            onClick={() => handleSetSectionVisible("about")}>
                            {t(`infoAbout.about`)}
                        </button>
                        <button
                            className={`infoAbout__button ${sectionVisible === "methodology" ? "isActive" : ""}`}
                            onClick={() => handleSetSectionVisible("methodology")}>
                            {t(`infoAbout.methodology`)}
                        </button>
                    </div>
                    <div className="infoAbout__right">
                        {sectionVisible === "about" && (
                            <div className="infoAbout__about">
                                <div className="infoAbout__scrolling">
                                    <p className="infoAbout__text"
                                        dangerouslySetInnerHTML={{ __html: t(`infoAbout.textAbout`) }}>
                                    </p>
                                </div>
                            </div>
                        )}
                        {sectionVisible === "methodology" && (
                            <div className="infoAbout__methodology">
                                <p className="infoAbout__methodologyTitle"
                                    dangerouslySetInnerHTML={{ __html: t(`infoAbout.titleMethodology`) }}>
                                </p>
                                <p className="infoAbout__text"
                                    dangerouslySetInnerHTML={{ __html: t(`infoAbout.textMethodology`) }}>
                                </p>
                                <ul className="infoAbout__list">
                                    {renderItemList()}
                                </ul>
                                <img className="infoAbout__asap" src="../../../images/about/asap.svg" alt="logo asap" />
                                <p className="infoAbout__text infoAbout__text--marginTop"
                                    dangerouslySetInnerHTML={{ __html: t(`infoAbout.textEndMethodology`) }}>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoAbout;