import "./Footer.scss"

const Footer = ({ title }) => {
    return (
        <div id="contacts" className="footer">
            <div className="footer__wrapper">
                <div className="footer__element footer__element--logo">
                    <img className="footer__logo" src="../../../images/footer/logo.svg" alt="logo instituto E" />
                </div>
                <div className="footer__element footer__element--contact">
                    <div className="footer__elementContact">
                        <p className="footer__elementContactTitle">{title}</p>
                        <a className="footer__elementContactInfo" href="mailto:webmaster@example.com">faleconosco@institutoe.org.br</a>
                        <a className="footer__elementContactInfo" href="tel:+ 55 21 2529 2876">+ 55 21 2529 2876</a>
                    </div>
                </div>
                <div className="footer__element footer__element--address">
                    <div className="footer__elementAddress">
                        <p className="footer__elementAddressInfo">Rua Aristídes Lobo, 150</p>
                        <p className="footer__elementAddressInfo--bold">Rio Comprido, RJ</p>
                        <p className="footer__elementAddressInfo">Brasil</p>
                    </div>
                </div>
                <div className="footer__element footer__element--social">
                    <div className="footer__elementSocial">
                        <a className="footer__elementSocialInfo" href="https://www.instagram.com/institutoe/" target="_blank">Instagram</a>
                        <a className="footer__elementSocialInfo" href="https://www.youtube.com/user/institutoe" target="_blank">Youtube</a>
                        <a className="footer__elementSocialInfo" href="https://www.facebook.com/Institutoeorg" target="_blank">Facebook</a>
                        <a className="footer__elementSocialInfo" href="https://www.linkedin.com/company/institutoe/about/" target="_blank">LinkedIn</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;