import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectAmazonGuardian) in translations
const pathProject = "projectAmazonGuardian";

const ProjectAmazonGuardian = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>Amazon Guardian | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/amazon-guardian/1.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/amazon-guardian/2.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          // subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p3`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p4`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p5`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p6`)}`
            },
            // Not used more
            // {
            //   type: "list",
            //   list: [
            //     {
            //       li: `${t(`${pathProject}.l1`)}`
            //     },
            //     {
            //       li: `${t(`${pathProject}.l2`)}`
            //     },
            //     {
            //       li: `${t(`${pathProject}.l3`)}`
            //     },
            //     {
            //       li: `${t(`${pathProject}.l4`)}`
            //     },
            //     {
            //       li: `${t(`${pathProject}.l5`)}`
            //     },
            //     {
            //       li: `${t(`${pathProject}.l6`)}`
            //     }
            //   ]
            // }
          ]} />

        <ProjectLinks
          links={[
            {
              linkUrl: "https://www.osklen.com.br/osklen_AG",
              textLink: `${t(`${pathProject}.linkText1`)}`
            }
          ]} />

        <ProjectVideos
          videos={[
            {
              linkYT: 'https://www.youtube.com/watch?v=S0yoKg6AV8o',
              textVideo: `${t(`${pathProject}.videoText1`)}`
            }
          ]} />
      </div>

      <ProjectBannerImage
        image={"../../../images/projects/amazon-guardian/shoe.png"} />

      <ProjectListTextImage
        list={[
          {
            title: `${t(`${pathProject}.jutaDaAmazonia.title`)}`,
            desc: `${t(`${pathProject}.jutaDaAmazonia.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/juta-da-amazonia.jpg",
            direction: "left"
          },
          {
            title: `${t(`${pathProject}.pirarucu.title`)}`,
            desc: `${t(`${pathProject}.pirarucu.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/pirarucu.jpg",
            direction: "right"
          },
          {
            title: `${t(`${pathProject}.latexNatural.title`)}`,
            desc: `${t(`${pathProject}.latexNatural.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/latex-natural.jpg",
            direction: "left"
          },
          {
            title: `${t(`${pathProject}.ecoLona.title`)}`,
            desc: `${t(`${pathProject}.ecoLona.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/eco-lona.jpg",
            direction: "right"
          },
          {
            title: `${t(`${pathProject}.evaVerde.title`)}`,
            desc: `${t(`${pathProject}.evaVerde.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/eva-verde.jpg",
            direction: "left"
          },
          {
            title: `${t(`${pathProject}.materiaisReciclados.title`)}`,
            desc: `${t(`${pathProject}.materiaisReciclados.desc`)}`,
            image: "../../../images/projects/amazon-guardian/list-text-image/materiais-reciclados.jpg",
            direction: "right"
          },
        ]} />

      <NextProject link="project-e-brigade"></NextProject>

    </div>
  )
};

export default ProjectAmazonGuardian;
