import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import { InfoAbout } from '../modules';

const About = () => {

  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <Fragment>

      <Helmet>
        <title>About | Instituto E</title>
      </Helmet>

      <InfoAbout></InfoAbout>

    </Fragment>
  )
};

export default About;
