import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import {
  NextProject,
  ProjectAwards,
  ProjectBannerImage,
  ProjectDescription,
  ProjectDownloads,
  ProjectImpacts,
  ProjectLinks,
  ProjectListTextImage,
  ProjectVideos,
} from '../atoms';
import BannerSlider from '../modules/BannerSlider/BannerSlider';

// Search this string (projectBase) in translations
const pathProject = "projectBase";

const ProjectBase = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <div className='projectLayout'>
      <Helmet>
        <title>Base | Instituto E</title>
      </Helmet>
      <BannerSlider
        slides={
          [
            {
              image: "../../../images/projects/1-example-1.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/1-example-2.jpg",
              alt: "description alt image",
            },
            {
              image: "../../../images/projects/1-example-3.jpg",
              alt: "description alt image",
            }
          ]}>
      </BannerSlider>

      {/* Possible to import all inside div with class: "projectLayoutContent", in the following order:
          - ProjectDescription
          - ProjectImpacts
          - ProjectAwards
          - ProjectLinks
          - ProjectVideos
          - ProjectDownloads
          - Outside the div: ProjectBannerImage
          - Outside the div: ProjectListTextImage */}

      <div className='projectLayoutContent'>
        <ProjectDescription
          title={`${t(`${pathProject}.title`)}`}
          subtitle={`${t(`${pathProject}.subtitle`)}`}
          paragraphs={[
            {
              type: "text",
              p: `${t(`${pathProject}.p1`)}`
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p2`)}`
            },
            {
              type: "list",
              list: [
                {
                  li: `${t(`${pathProject}.l1`)}`
                },
                {
                  li: `${t(`${pathProject}.l2`)}`
                },
                {
                  li: `${t(`${pathProject}.l3`)}`
                }
              ]
            },
            {
              type: "text",
              p: `${t(`${pathProject}.p3`)}`
            }
          ]} />

        <ProjectImpacts
          impacts={[
            {
              impactTitle: `${t(`${pathProject}.impactTitle1`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle1`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle2`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle2`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle3`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle3`)}`
            },
            {
              impactTitle: `${t(`${pathProject}.impactTitle4`)}`,
              impactSubtitle: `${t(`${pathProject}.impactSubtitle4`)}`
            }
          ]} />

        <ProjectAwards
          awards={[
            {
              imageLogo: '../../../images/logos/logo-test.svg',
              textAward: `${t(`${pathProject}.awardText1`)}`
            },
            {
              imageLogo: '../../../images/logos/logo-test.svg',
              textAward: `${t(`${pathProject}.awardText2`)}`
            },
            {
              imageLogo: '../../../images/logos/logo-test.svg',
              textAward: `${t(`${pathProject}.awardText3`)}`
            }
          ]} />

        <ProjectLinks
          links={[
            {
              linkUrl: "https://www.instagram.com",
              textLink: `${t(`${pathProject}.linkText1`)}`
            },
            {
              linkUrl: "https://www.facebook.com",
              textLink: `${t(`${pathProject}.linkText2`)}`
            },
            {
              linkUrl: "https://www.tiktok.com",
              textLink: `${t(`${pathProject}.linkText3`)}`
            }
          ]} />

        <ProjectVideos
          videos={[
            {
              linkYT: 'https://youtu.be/Hy8kmNEo1i8',
              textVideo: `${t(`${pathProject}.videoText1`)}`
            },
            {
              linkYT: 'https://youtu.be/Hy8kmNEo1i8',
              textVideo: `${t(`${pathProject}.videoText2`)}`
            },
            {
              linkYT: 'https://youtu.be/Hy8kmNEo1i8',
              textVideo: `${t(`${pathProject}.videoText3`)}`
            }
          ]} />

        <ProjectDownloads
          downloads={[
            {
              fileToDownload: "../../../pdf/example.pdf",
              textDownload: `${t(`${pathProject}.downloadText1`)}`
            },
            {
              fileToDownload: "../../../pdf/example.pdf",
              textDownload: `${t(`${pathProject}.downloadText2`)}`
            },
            {
              fileToDownload: "../../../pdf/example.pdf",
              textDownload: `${t(`${pathProject}.downloadText3`)}`
            }
          ]} />
      </div>

      <NextProject link="project-base"></NextProject>

    </div>
  )
};

export default ProjectBase;
