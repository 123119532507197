import { Parallax } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';

import "./FollowUs.scss"
import { Button } from '../../atoms';

const FollowUs = () => {

    const { t } = useTranslation();

    return (
        <div className='followUs'>
            <div className='followUs__content'>
                <div className='followUs__left'>
                    <Parallax speed={12}>
                        <h2 className='followUs__title'>{t('followUs.title')}</h2>
                        <Button label={t('followUs.buttonText')} link="https://www.instagram.com/institutoe/"></Button>
                    </Parallax>
                </div>
                <div className='followUs__right'>
                    <Parallax speed={-30}>
                        <div className='followUs__listImages followUs__listImages--firstColumn'>
                            <img className='followUs__img' src="../../../images/followUs/l1.png" />
                            <img className='followUs__img' src="../../../images/followUs/l2.png" />
                            <img className='followUs__img' src="../../../images/followUs/l3.png" />
                            <img className='followUs__img' src="../../../images/followUs/l1.png" />
                            <img className='followUs__img' src="../../../images/followUs/l2.png" />
                            <img className='followUs__img' src="../../../images/followUs/l3.png" />
                        </div>
                    </Parallax>
                    <Parallax speed={30}>
                        <div className='followUs__listImages followUs__listImages--secondColumn'>
                            <img className='followUs__img' src="../../../images/followUs/r1.png" />
                            <img className='followUs__img' src="../../../images/followUs/r2.png" />
                            <img className='followUs__img' src="../../../images/followUs/r3.png" />
                            <img className='followUs__img' src="../../../images/followUs/r1.png" />
                            <img className='followUs__img' src="../../../images/followUs/r2.png" />
                            <img className='followUs__img' src="../../../images/followUs/r3.png" />
                        </div>
                    </Parallax>
                </div>
            </div>
        </div>
    )
}

export default FollowUs;