import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';
import { SixEItem } from "../../atoms";
import "./SeiE.scss"

const listSixE = ["earth", "environment", "energy", "education", "empowerment", "economics"];

const contentSixE = {
    earth: {
        key: "earth",
        title: "<span>e</span>arth",
        logo: "earth",
        image: "earth"
    },
    environment: {
        key: "environment",
        title: "<span>e</span>nvironment",
        logo: "environment",
        image: "environment"
    },
    energy: {
        key: "energy",
        title: "<span>e</span>nergy",
        logo: "energy",
        image: "energy"
    },
    education: {
        key: "education",
        title: "<span>e</span>ducation",
        logo: "education",
        image: "education"
    },
    empowerment: {
        key: "empowerment",
        title: "<span>e</span>mpowerment",
        logo: "empowerment",
        image: "empowerment"
    },
    economics: {
        key: "economics",
        title: "<span>e</span>conomics",
        logo: "economics",
        image: "economics"
    }
}

const SixE = ({ title }) => {

    const { t } = useTranslation();

    const [idClicked, setIdClicked] = useState(null);
    const [openSixE, setOpenSixE] = useState(null);
    const [mouseEnterWrapper, setMouseEnterWrapper] = useState("");
    const [renderedSixE, setRenderedSixE] = useState(null);

    useEffect(
        () => {
            const tmp = contentSixE[idClicked];
            setRenderedSixE(tmp)
        }, [idClicked]
    )

    const toggleSixE = () => {
        setOpenSixE(!openSixE);
        document.getElementsByTagName("html")[0].classList.toggle("blockScroll")
    }

    const handleSetIdClicked = (identificator) => {
        setIdClicked(identificator);
        toggleSixE();
    }

    const handleRenderSixEItem = (type, key) => {
        return (
            <SixEItem key={key} logo={type} title={contentSixE[type].title} identificator={type} clickPassed={handleSetIdClicked}></SixEItem>
        )
    }

    const handleMouseEnter = (e) => {
        e.preventDefault()
        setMouseEnterWrapper("active");
    }

    const handleMouseLeave = (e) => {
        e.preventDefault()
        setMouseEnterWrapper("");
    }

    const handleRenderSixE = useCallback(
        (el, key) => {
            return el ? (
                <div className="pageSixE__wrapper" key={key}>
                    <div className='pageSixE__backButton' onClick={(toggleSixE)}>
                        <img className="iconBackHome" src="images/general/arrow.svg" alt="go to project" />
                        <p className='bannerSlider__textBackHome'>{t("sixE.buttonBackHome")}</p>
                    </div>
                    <div className="pageSixE__background">
                        <img src={`images/sixE/${el.image}.jpg`} className="sixE__image"></img>
                    </div>
                    <div className='pageSixE__content'>
                        <div className='pageSixE__titleWrapper'>
                            <img src={`images/logos/${el.logo}.svg`} className="pageSixE__logo"></img>
                            <p className='pageSixE__title' dangerouslySetInnerHTML={{ __html: el.title }}></p>
                        </div>
                        <p className='pageSixE__description' dangerouslySetInnerHTML={{ __html: t(`sixE.${el.key}`) }}></p>
                    </div>
                </div>) : ""
        }, [renderedSixE]
    );

    return (
        <div className={`pageSixE ${openSixE ? "pageSixE--open" : ""}`}>
            {handleRenderSixE(renderedSixE)}
            <div className="sixE">

                <div className="sixE__background">
                    <Parallax speed={-30} className="sixE__backgroundParallax">
                        <img src="images/sixE/sixE.jpg" className="sixE__image"></img>
                    </Parallax>
                </div>

                <div className="sixE__content">
                    <div className="sixE__titleWrapper">
                        <p className="sixE__title">{title}</p>
                    </div>
                    <div className={`sixE__wrapper ${mouseEnterWrapper}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        {
                            listSixE.map((type, key) => handleRenderSixEItem(type, key))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
export default SixE;