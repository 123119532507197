import "./BannerQuote.scss"
import { useTranslation } from 'react-i18next';
import { useIntersection } from 'react-use';
import { Parallax } from 'react-scroll-parallax';

const BannerQuote = ({ }) => {
    const { t } = useTranslation();
    return (
        <div className="bannerQuote">
            <Parallax speed={12}>
                <span className="bannerQuote__symbol">“</span>
                <h2 className="bannerQuote__cite" dangerouslySetInnerHTML={{ __html: t(`quote.title`) }} ></h2>
            </Parallax>
        </div>
    )
}

export default BannerQuote;