import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Footer } from "../atoms";
import { BannerMain, BannerQuote, BannerVideo, Slider, SixE, FollowUs, About, Partners } from '../modules';

const Home = () => {

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng);

    if (hash) {
      const anchor = document.querySelector(hash);
      anchor.scrollIntoView({ behavior: "instant" });
      window.history.replaceState(null, null, ' ');
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>Instituto E</title>
      </Helmet>

      <BannerMain srcVideo={`videos/bannerMain.mp4`}></BannerMain>

      <div id="about">
        <About></About>
      </div>

      <SixE title={t('sixE.title')}></SixE>

      <BannerQuote></BannerQuote>

      <BannerVideo srcVideo={`videos/bannerVideo.mp4`}></BannerVideo>

      <div id="projects">
        <Slider
          title={t('slider.mainTitle')}
          slides={
            [
              {
                image: "../../../images/projects/restinga/slider-restinga.jpg",
                alt: "background restinga",
                title: "projectTitleRestinga",
                // subtitle: "projectSubtitleRestinga",
                linkToProject: "project-restinga"
              },
              {
                image: "../../../images/projects/asap/slider-asap.jpg",
                alt: "background asap",
                title: "projectTitleAsap",
                // subtitle: "projectSubtitleAsap",
                linkToProject: "project-asap"
              },
              {
                image: "../../../images/projects/amazon-guardian/slider-amazon-guardian.jpg",
                alt: "background amazon guardian",
                title: "projectTitleAmazonGuardian",
                // subtitle: "projectSubtitleAmazonGuardian",
                linkToProject: "project-amazon-guardian"
              },
              {
                image: "../../../images/projects/e-brigade/slider-e-brigade.jpg",
                alt: "background e-brigade",
                title: "projectTitleEBrigade",
                // subtitle: "projectSubtitleEBrigade",
                linkToProject: "project-e-brigade"
              },
              {
                image: "../../../images/projects/e-fabrics/slider-e-fabrics.jpg",
                alt: "background e-fabrics",
                title: "projectTitleEFabrics",
                // subtitle: "projectSubtitleEFabrics",
                linkToProject: "project-e-fabrics"
              },
              {
                image: "../../../images/projects/hub-e/slider-hub-e.jpg",
                alt: "background hub-e",
                title: "projectTitleHubE",
                // subtitle: "projectSubtitleHubE",
                linkToProject: "project-hub-e"
              },
              {
                image: "../../../images/projects/ashaninka/slider-ashaninka.jpg",
                alt: "background ashaninka",
                title: "projectTitleAshaninka",
                // subtitle: "projectSubtitleAshaninka",
                linkToProject: "project-ashaninka"
              },
              {
                image: "../../../images/projects/portobello/slider-portobello.jpg",
                alt: "background portobello",
                title: "projectTitlePortobello",
                // subtitle: "projectSubtitlePortobello",
                linkToProject: "project-portobello"
              },
              {
                image: "../../../images/projects/traces/slider-traces.jpg",
                alt: "background traces",
                title: "projectTitleTraces",
                // subtitle: "projectSubtitleTraces",
                linkToProject: "project-traces"
              },
              {
                image: "../../../images/projects/water-traces/slider-water-traces.jpg",
                alt: "background water-traces",
                title: "projectTitleWaterTraces",
                // subtitle: "projectSubtitleWaterTraces",
                linkToProject: "project-water-traces"
              },
              {
                image: "../../../images/projects/keel/slider-keel.jpg",
                alt: "background keel",
                title: "projectTitleKeel",
                // subtitle: "projectSubtitleKeel",
                linkToProject: "project-keel"
              },
              {
                image: "../../../images/projects/sustainability/slider-sustainability.jpg",
                alt: "background sustainability",
                title: "projectTitleSustainability",
                // subtitle: "projectSubtitleSustainability",
                linkToProject: "project-sustainability"
              },
              {
                image: "../../../images/projects/e-ayiti/slider-e-ayiti.jpg",
                alt: "background e-ayiti",
                title: "projectTitleEAyiti",
                // subtitle: "projectSubtitleEAyiti",
                linkToProject: "project-e-ayiti"
              },
              {
                image: "../../../images/projects/e-award/slider-e-award.jpg",
                alt: "background e-award",
                title: "projectTitleEAward",
                // subtitle: "projectSubtitleEAward",
                linkToProject: "project-e-award"
              },
              // {
              //   image: "../../../images/projects/osklen/slider-osklen.jpg",
              //   alt: "background osklen",
              //   title: "projectTitleOsklen",
              //   // subtitle: "projectSubtitleOsklen",
              //   linkToProject: "project-osklen"
              // },
            ]}>
        </Slider>
      </div>

      <Partners
        title={t('partners.quote')}
        slides={
          [
            {
              partners: [
                {
                  image: "logo-unesco",
                  linkToProject: "https://www.unesco.org/en",
                },
                {
                  image: "logo-osklen",
                  linkToProject: "https://www.osklen.com.br/",
                },
                {
                  image: "logo-greenpeople",
                  linkToProject: "https://www.greenpeople.co.uk/",
                },
                {
                  image: "logo-janeiro-hotel",
                  linkToProject: "https://janeirohotel.rio/",
                },
                {
                  image: "logo-solar-grid",
                  linkToProject: "https://www.solargrid.com.br/",
                },
                {
                  image: "logo-wwf",
                  linkToProject: "https://www.worldwildlife.org/",
                },
                {
                  image: "logo-conservation-internacional",
                  linkToProject: "https://www.conservation.org/",
                },
                {
                  image: "logo-carta-de-la-tierra",
                  linkToProject: "https://cartadelatierra.org/lea-la-carta-de-la-tierra/preambulo/",
                },
                {
                  image: "logo-ministero-ambiente-italia",
                  linkToProject: "https://www.governo.it/it/ministeri/ministero-dellambiente-e-della-tutela-del-territorio-e-del-mare",
                }, {
                  image: "logo-omart",
                  linkToProject: "https://www.instagram.com/_om.art_/",
                }
              ]
            },
            {
              partners: [
                {
                  image: "logo-sos-mata-atlantica",
                  linkToProject: "https://www.sosma.org.br/",
                },
                {
                  image: "logo-gota",
                  linkToProject: "https://umagotanooceano.org/",
                },
                {
                  image: "logo-brazil-foundation",
                  linkToProject: "https://www.brazilfoundation.org/",
                },
                {
                  image: "logo-observatorio-do-codigo-forestal",
                  linkToProject: "https://observatorioflorestal.org.br/",
                },
                {
                  image: "logo-itc",
                  linkToProject: "https://ethicalfashioninitiative.org/",
                }
              ]
            }

          ]}
      ></Partners>

      <FollowUs></FollowUs>

      <Footer title={t('footer.contact')}></Footer>

    </Fragment>
  )
};

export default Home;
