import "./ProjectDescription.scss"

const ProjectDescription = ({ title, subtitle, paragraphs }) => {

    return (
        <div className='projectDescription'>
            <div className='projectDescription__intro'>
                <h1 className='projectDescription__title' dangerouslySetInnerHTML={{ __html: title }}></h1>
                {subtitle && <p className='projectDescription__subtitle' dangerouslySetInnerHTML={{ __html: subtitle }}></p>}
            </div>
            <div className='projectDescription__info'>
                {paragraphs.map((el, keyPar) => {
                    if (el.type === "text") {
                        return (
                            <p className='projectDescription__text' key={keyPar}
                                dangerouslySetInnerHTML={{ __html: el.p }}>
                            </p>
                        );
                    }
                    if (el.type === "list") {
                        return (
                            <ul className='projectDescription__list' key={keyPar}>
                                {el.list.map((itemList, keyList) => {
                                    return (
                                        <li className='projectDescription__itemList' key={keyList}>
                                            <p className='projectDescription__textItemList'
                                                dangerouslySetInnerHTML={{ __html: itemList.li }}>
                                            </p>
                                        </li>
                                    )
                                })}
                            </ul>
                        );
                    }
                })}
            </div>
        </div>
    )
}

export default ProjectDescription;