import { useTranslation } from 'react-i18next';

import "./ProjectBannerImage.scss"

const ProjectBannerImage = ({ image }) => {

    const { t } = useTranslation();

    return (
        <div className='projectBannerImage'>
            <img className='projectBannerImage__img' src={`${image}`}></img>
        </div>
    )
}

export default ProjectBannerImage;