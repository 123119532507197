import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';

import "./ProjectListTextImage.scss"

const ProjectListTextImage = ({ list }) => {

    const { t } = useTranslation();

    return (
        <div className='projectListTextImage'>
            <div className='projectListTextImage__content'>
                {list.map((singleBlock, keyBlock) => {
                    return (
                        <div className={`projectListTextImage__block ${singleBlock.direction}`} key={keyBlock}>

                            <div className='projectListTextImage__blockImage'>
                                <Parallax speed={12}>
                                    <img className='projectListTextImage__image'
                                        src={`${singleBlock.image}`} />
                                </Parallax>
                            </div>

                            <div className='projectListTextImage__blockText'>
                                <Parallax speed={-6}>
                                    <p className='projectListTextImage__title'>{singleBlock.title}</p>
                                    <div className='projectListTextImage__desc'
                                        dangerouslySetInnerHTML={{ __html: singleBlock.desc }}>
                                        {/* This dangerouslySetInnerHTML is a richText */}
                                    </div>
                                </Parallax>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectListTextImage;