import "./BannerVideo.scss"

const BannerVideo = ({srcVideo}) => {
    return (
        <div className="bannerVideo">
            <div className='bannerVideo__background'>
                <video className="bannerVideo__video" muted autoPlay loop playsInline>
                    <source src={srcVideo} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default BannerVideo;